$tablet-landscape-min: 1024px;
$tablet-portrait-min: 768px;
$mobile-landscape-min: 667px;
$mobile-landscape-max: 915px;
$mobile-portrait-min: 390px;

//media
$breakpoint-desktop-min: 1025px;
$breakpoint-tablet-portrait-min: 768px;
$breakpoint-tablet-landscape-max: 1366px;
$breakpoint-mobile-portrait-max: 1024px;
$breakpoint-mobile-landscape-max: 1023px;

// mobile
$breakpoint-mobile-portrait-xs: 320px;
$breakpoint-mobile-portrait-md: 375px;
$breakpoint-mobile-portrait-lg: 414px;

$breakpoint-mobile-landscape-xs: 568px;
$breakpoint-mobile-landscape-md: 667px;
$breakpoint-mobile-landscape-lg: 823px;

// tablet
$breakpoint-tablet-portrait-xs: 768px;
$breakpoint-tablet-portrait-md: 1024px;

$breakpoint-tablet-landscape-xs: 1024px;
$breakpoint-tablet-landscape-md: 1366px;

// desktop
$breakpoint-laptop-l: 1440px;
$breakpoint-desktop-fhd: 1920px;

//bottom
$bottom-height-percent: 5%;
$bottom-height-percent-mobile-portrait: 7.5%;

:root {
    --game-color: #FFCC48;
    --font-family: 'NotoSans-SemiCondensedBold';
    --bottom-height-percent-landscape: 8%;
    --bottom-height-percent-portrait: 8%;
}

@media screen and (max-width: $mobile-landscape-max) and (orientation: landscape) {
    :root {
        --bottom-height-percent-landscape: 10%;
    }
}

@media screen and (min-width: 1181px) and (orientation: landscape) {
    :root {
        --bottom-height-percent-landscape: 5%;
    }
}